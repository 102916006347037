import React, { useEffect, useState } from "react";
import { Table, Switch as AntSwitch, Button } from "antd";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { GoEye } from "react-icons/go";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import imageNotFound from "../../../assets/imagesNotFound.png";
import appAndmoreApi from "../../../services/appAndmoreApi";

import style from "../../../pages/admin/studios/studio.module.css";
import CopyToClipboard from "../../common/CopyToClipboard ";
import Switch from "../../common/Switch";
import DateAndSearchFilter from "../../filters/DateAndSearchFilterComponent";
import PaginationNav from "../../pagination/PaginationNav";
import CustomSkeletonTable from "../../loader/CustomSkeletonTable ";

const Dance = ({
  products,
  setProducts,
  setPageCount,
  pageCount,
  totalPage,
  bookingPageCount,
  setTotalPage,
  sendFilterDataToapi,
  perPage,
  pageType,
}) => {
  const [pid, setPid] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setProducts([])
  }, [bookingPageCount])

  const handleSwitchChange = (studioId, isActive) => {
    setShowLoader(true);
    appAndmoreApi
      .updateServiceStatus(studioId, isActive ? 0 : 1)
      .then((response) => {
        setProducts((prevState) =>
          prevState.map((product) =>
            product._id === studioId
              ? { ...product, isActive: response.updatedService.isActive }
              : product
          )
        );
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        setShowLoader(false);
      });
  };

  const gotoEdit = (id,type) => {
    if(type === "view"){
      const selectedProduct = products.find((product) => product._id === id);
    navigate(`/entity/edit?id=${id}`, {
      state: { showMode: true, navCount: 5,productData: selectedProduct },
    });
    }else{

      const selectedProduct = products.find((product) => product._id === id);
      navigate(`/entity/edit?id=${id}`, {
        state: { productData: selectedProduct, isEditMode: true, navCount: 5 },
      });
    }
  };

 

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "srNo",
      key: "srNo",
      render: (text, record, index) => index + 1 + (pageCount - 1) * perPage,
    },
    {
      title: "Name",
      dataIndex: "fullName",
      render: (text, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className={
                record.profileUrl === ""
                  ? `${style.studioImageNotFound}`
                  : `${style.studioImage} `
              }
            >
              <img
                src={record.photos?.[0] || imageNotFound}
                alt=""
                onError={(e) => {
                  e.target.src = imageNotFound;
                }}
              />
            </div>
            &nbsp;&nbsp;
            <CopyToClipboard textToCopy={record.name} />
          </div>
        </>
      ),
    },
    {
      title: "Price",
      dataIndex: "pricing",
      render: (pricing) => `Starting from ₹${pricing?.["IN"]?.price || 0}`,
    },
    {
      title: "Location",
      dataIndex: "address",
      render: (address, record) => (
        <>
        <CopyToClipboard textToCopy={address} />
          
          <br />
          <small>{record.state}</small>
        </>
      ),
    },
    {
      title: "No. of Rooms",
      dataIndex: "listingDetails",
      // sorter: (a, b) =>
      //   moment(a.creationTimeStamp) - moment(b.creationTimeStamp),
      render: (listingDetails) =>
        listingDetails?.length || 0,
    },
    {
      title: "Created on",
      dataIndex: "creationTimeStamp",
      // sorter: (a, b) =>
      //   moment(a.creationTimeStamp) - moment(b.creationTimeStamp),
      render: (creationTimeStamp) =>
        moment(creationTimeStamp).format("Do MMM YY, hh:mm a"),
    },
    {
      title: "Activity Status",
      dataIndex: "isActive",
      render: (isActive, record) => (
        <Switch
          status={isActive}
          // isloading={pid === record._id && showLoader}
          switchDisabled={true}
          // onClick={() => {
          //   setPid(record._id);
          //   handleSwitchChange(record._id, isActive);
          // }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (id) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <GoEye
            onClick={() => pageType !== "Booking" && gotoEdit(id,"view")}
            style={{ cursor: "pointer" }}
          />
          {
            pageType !== "Booking" && (
             
              <MdEdit
              onClick={() => gotoEdit(id)}
              style={{ color: "#ffc701", cursor: "pointer" }}
            />
            
            )
          }
         
          <RiDeleteBin5Fill style={{ color: "red", cursor: "pointer" }} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={style.studioTabelDiv}>
        <DateAndSearchFilter
          setProducts={setProducts}
          setTotalPage={setTotalPage}
          bookingPageCount={bookingPageCount}
          sendFilterDataToapi={sendFilterDataToapi}
          searchDisable={true}
          dateDisable={true}
        />

        <Table
          columns={columns}
          dataSource={products}
          pagination={false}
          rowKey="_id"
          locale={{
            emptyText: <CustomSkeletonTable columnCount={columns?.length} />,
          }}
        />
      </div>
      <div className={style.tabelpaginationDiv}>
        <PaginationNav
          pageCount={pageCount}
          totalPage={totalPage}
          setPageCount={setPageCount}
          bookingPageCount={bookingPageCount}
        />
      </div>
    </>
  );
};

export default Dance;
