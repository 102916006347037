import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import { MdOutlineAddBox } from "react-icons/md";
import CustomSelect from "../inputs/CustomSelect";
import { RxCross2 } from "react-icons/rx";
import style from "../../pages/admin/studios/studio.module.css";

import CustomTimePicker from "../inputs/CustomTimePicker";
import dayjs from "dayjs";
import moment from "moment";
import cross from "../../assets/cross.svg";
import { use } from "react";
import { confirmAlret, errorAlert, sucessAlret } from "../alert/Alert";
import { nanoid } from "nanoid";
import { compareObj } from "../../utils/helperFunction";
import { current } from "@reduxjs/toolkit";

const Availability = ({
  showPopUp,
  setshowPopUp,
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  handleSubmit,
  indexofrooms,
  handleBookingsDay,
  isEditMode,
  showMode,
}) => {
  const defaultStartTime = dayjs("00:00", "HH:mm").format("HH:mm");
  const defaultEndTime = dayjs("23:59", "HH:mm").format("HH:mm");
  const [availabilities, setAvailabilities] = useState({
    allDays: {
      monday: {
        availabilityType: 0,
        sameAsGeneralTime: false,
      },
      tuesday: {
        availabilityType: 0,
        sameAsGeneralTime: false,
      },
      wednesday: {
        availabilityType: 0,
        sameAsGeneralTime: false,
      },
      thursday: {
        availabilityType: 0,
        sameAsGeneralTime: false,
      },
      friday: {
        availabilityType: 0,
        sameAsGeneralTime: false,
      },
      saturday: {
        availabilityType: 0,
        sameAsGeneralTime: false,
      },
      sunday: {
        availabilityType: 0,
        sameAsGeneralTime: false,
      },
    },
    repeateForAllDays: false,
  });
  


  const initialBookingDays = [
    "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"
  ].map((day) => ({
    day,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  }));
  
  const [dataTosend, setDataTosend] = useState({
    generalTime: {
      startTime: "",
      endTime: "",
    },
    bookingDays: initialBookingDays,
  });

  

  
  
  const [previousObj, setPreviousObj] = useState({});

  const addNewBookingEntry = () => {
    const newEntry = {
      id: `${currentDay}-${nanoid()}-${nanoid()}`, // Unique identifier
      day: currentDay,
      startTime: "",
      endTime: "",
    };

    setDataTosend((prevData) => ({
      ...prevData,
      bookingDays: [...prevData.bookingDays, newEntry],
    }));
  };

  const [currentDay, setcurrentDay] = useState("monday");

  const handelDateUpdate = (e, day) => {
    setcurrentDay(day);
  };
  const [currentBookingLength, setcurrentBookingLength] = useState(
    dataTosend.bookingDays.filter((data) => data.day === currentDay).length
  );

  useEffect(() => {
    const filteredDaysLength = dataTosend.bookingDays.filter(
      (data) => data.day === currentDay
    ).length;
    
    setcurrentBookingLength(filteredDaysLength);
    

  
    // Check if dataTosend is different from previousData
    const isDataChanged = !compareObj(dataTosend, previousObj);
    console.log("isDataChanged-----------", isDataChanged);
  
    // Update availabilities state only if the value needs to change
    if (availabilities.repeateForAllDays !== !isDataChanged) {
      setAvailabilities((prevAvailabilities) => ({
        ...prevAvailabilities,
        repeateForAllDays: !isDataChanged,
      }));
    }
  }, [currentDay, dataTosend, previousObj, availabilities.repeateForAllDays]);

  useEffect(() => {
    if (availabilities.allDays[currentDay].availabilityType === 0) {
      const { generalTime } = dataTosend;

      if (generalTime?.startTime && generalTime?.endTime) {
        // Handle "Same as General Time" with existing generalTime
        setDataTosend((prevData) => {
          const updatedBookingDays = prevData.bookingDays.filter(
            (day) => day.day !== currentDay
          );

          return {
            ...prevData,
            bookingDays: [
              ...updatedBookingDays,
              {
                id: `${currentDay}-${nanoid()}`, // Unique ID for the new entry
                day: currentDay,
                startTime: generalTime.startTime,
                endTime: generalTime.endTime,
              },
            ],
          };
        });
      } else {
        // Set default generalTime and update `bookingDays`

        setDataTosend((prevData) => {
          const updatedBookingDays = prevData.bookingDays.filter(
            (day) => day.day !== currentDay
          );

          return {
            ...prevData,
            generalTime: {
              startTime: defaultStartTime,
              endTime: defaultEndTime,
            },
                     };
        });
      }
    }

    if (availabilities.allDays[currentDay].availabilityType === 2) {
      let data = { ...dataTosend };
      data.bookingDays = data.bookingDays.filter(
        (data) => data.day !== currentDay
      );

      setDataTosend(data);
    }
  }, [availabilities.allDays[currentDay]?.availabilityType, currentDay]);

  const handelAvailability = (availabilityType) => {
    // Update availabilities state
    setAvailabilities((prevAvailabilities) => ({
      ...prevAvailabilities,
      allDays: {
        ...prevAvailabilities.allDays,
        [currentDay]: {
          ...prevAvailabilities.allDays[currentDay],
          availabilityType, // Using the passed value directly
        },
      },
    }));
    if (availabilityType === 2) {
      console.log("7&&&&&&&&&&&&&&&&&", availabilityType);
      setDataTosend((prevData) => ({
        ...prevData,
        bookingDays: prevData.bookingDays.filter(
          (data) => data.day !== currentDay
        ),
      }));
    }

    // Handle generalTime logic
  };

  const handelGeneralTime = (time, timeString, type) => {
    // if (!time || !timeString) {
    //   console.warn(`Invalid time for ${type}. No changes made.`);
    //   return;
    // }

    setDataTosend((prevData) => ({
      ...prevData,
      generalTime: {
        ...prevData.generalTime,
        [type]: timeString,
      },
    }));
  };
  const getCurrentFilterObject = () => {
    return dataTosend.bookingDays.filter((entry) => entry.day === currentDay);
  };
  const handleBookingTimeChange = (timeString, type, id) => {
    setDataTosend((prevData) => ({
      ...prevData,
      bookingDays: prevData.bookingDays.map((bookingDay) =>
        bookingDay.id === id
          ? {
              ...bookingDay,
              [type]: timeString,
            }
          : bookingDay
      ),
    }));
  };

  const handleBookingEntryDelete = (id) => {
    setDataTosend((prevData) => ({
      ...prevData,
      bookingDays: prevData.bookingDays.filter(
        (bookingDay) => bookingDay.id !== id
      ),
    }));
  };
  const handelSameAsGeneralTime = (event) => {
    const checked = event?.target?.checked ?? true;
  
    console.log("event.target", event?.target);
    console.log("-----checked", checked);
  
    if (!dataTosend.generalTime.startTime || !dataTosend.generalTime.endTime) {
      errorAlert("Please enter general time first");
      return;
    }
  
    setAvailabilities((prevAvailabilities) => ({
      ...prevAvailabilities,
      allDays: {
        ...prevAvailabilities.allDays,
        [currentDay]: {
          ...prevAvailabilities.allDays[currentDay],
          sameAsGeneralTime: checked,
        },
      },
    }));
  
    if (checked) {
      setDataTosend((prevData) => ({
        ...prevData,
        bookingDays: [
          ...prevData.bookingDays.filter((day) => day.day !== currentDay),
          {
            id: `${currentDay}-${nanoid()}`, // Unique ID for the new entry
            day: currentDay,
            startTime: prevData.generalTime.startTime,
            endTime: prevData.generalTime.endTime,
          },
        ],
      }));
    }
  };
  

  const handleRepeatForAllDays = (event) => {
    const { checked } = event.target;
    console.log("Checked:", checked);
  
    // If unchecked, update the state and exit early
    if (!checked) {
      setAvailabilities((prev) => ({
        ...prev,
        repeateForAllDays: false,
      }));
      return;
    }
  
    // If there are no bookings, show an error
    if (dataTosend.bookingDays.length === 0) {
      errorAlert("No current booking time found. Please set it first.");
      return;
    }
  
    // Get current bookings and validate them
    const currentBookings = getCurrentFilterObject();
    if (currentBookings.length === 0 || currentBookings.some((booking) => !booking.startTime || !booking.endTime)) {
      errorAlert("Please set valid booking times for the current day.");
      return;
    }
  
    // Update the booking days for all days
    const allDays = Object.keys(availabilities.allDays);
    const updatedBookingDays = allDays.flatMap((day) =>
      currentBookings.map((booking) => ({
        id: `${day}-${nanoid()}-${nanoid()}`,
        day,
        startTime: booking.startTime,
        endTime: booking.endTime,
      }))
    );
  
    // Update availability type for all days
    const updatedAvailabilities = {
      ...availabilities,
      allDays: allDays.reduce((acc, day) => {
        acc[day] = { ...availabilities.allDays[day], availabilityType: availabilities.allDays[currentDay].availabilityType };
        return acc;
      }, {}),
    };
  
    // Update the state
    setAvailabilities((prev) => ({
      ...updatedAvailabilities,
      repeateForAllDays: true,
    }));
    setDataTosend((prevData) => ({
      ...prevData,
      bookingDays: updatedBookingDays,
    }));
    setPreviousObj((prev) => ({ ...prev, bookingDays: updatedBookingDays }));
  
    sucessAlret("Repeated for all days");
  };
  
  const saveAvailability = () => {
    setshowPopUp(false);

    setFieldValue(
      `listingDetails.[${indexofrooms}].bookingDays`,
      dataTosend.bookingDays
    );
    setFieldValue(
      `listingDetails.[${indexofrooms}].generalTime`,
      dataTosend.generalTime
    );
  };
  const handleCancel = () => {
    confirmAlret("Are you sure you want to cancel?").then((result) => {
      if (result.isConfirmed) {
        setshowPopUp(false);
        if (values.listingDetails[indexofrooms].tempBookingDays === "custom") {
          setFieldValue(
            `listingDetails.[${indexofrooms}].tempBookingDays`,
            "allDays"
          );
          handleBookingsDay("allDays");
        } else {
          setFieldValue(`listingDetails.[${indexofrooms}].tempBookingDays`, "");
        }

        // setFieldValue("bookingDays", previousObj.bookingDays);
      }
    });
  };

  useEffect(() => {
    if (isEditMode || showMode) {
      const roomDetails = values?.listingDetails?.[indexofrooms];
  
      const data = {
        generalTime: roomDetails?.generalTime,
        bookingDays: roomDetails?.bookingDays,
      };
  
      setDataTosend(data);
  
      // Clone availabilities to prevent direct state mutation
      const updatedAvailabilities = { ...availabilities };
  
      Object.keys(availabilities.allDays).forEach((d1) => {
        const lowerCaseDay = d1.toLowerCase();
        const bookingDaysLower = data.bookingDays.map((d3) => d3.day.toLowerCase());
  
        if (bookingDaysLower.includes(lowerCaseDay)) {
          const dayData = data.bookingDays[lowerCaseDay];
          updatedAvailabilities.allDays[d1].availabilityType =
            dayData?.startTime === defaultStartTime && dayData?.endTime === defaultEndTime ? 0 : 1;
        } else {
          updatedAvailabilities.allDays[d1].availabilityType = 2;
        }
      });
  
      setAvailabilities(updatedAvailabilities);
    }
  }, [showMode, isEditMode]);
  
  return (
    <>
      <div
        className={style.addNewListing}
        style={{ display: !showPopUp ? "none" : "flex" }}
      >
        <div
          className={`${style.addNewListingPopUp} ${
            showPopUp ? style.addNewListingPopUpAfter : ""
          }`}
        >
          <div>
            <h2>Set Availability</h2>
            <RxCross2 onClick={handleCancel} style={{ cursor: "pointer" }} />
          </div>
          <div className={style.addNewListingPopUpMain}>
            <div>
              {Object.keys(availabilities.allDays).map((data, index) => (
                <div
                  key={index+1}
                  id={index}
                  style={{
                    backgroundColor: data === currentDay && "#FFC701",
                    borderC: data === currentDay && "none",
                    // borderColor: Object.values(availabilities.allDays).some(
                    //   (day) =>
                    //     day.availabilityType === 0 || day.availabilityType === 1
                    // )
                    //   ? "#FFC701"
                    //   : "",
                    borderColor:
                      (availabilities.allDays[data].availabilityType === 0 ||
                        availabilities.allDays[data].availabilityType === 1) &&
                      "#FFC701",
                  }}
                  onClick={(e) => {
                    handelDateUpdate(e, data);
                  }}
                >
                  {data[0].toUpperCase() + data.slice(1, 3)}
                </div>
              ))}
            </div>

            <div className={style.addNewListingPopUpAvailability}>
              <div>
                <div>
                  <input
                    type="radio"
                    id="a"
                    name="a"
                    checked={
                      availabilities.allDays[currentDay].availabilityType === 0
                    }
                    onClick={() => handelAvailability(0)}
                  />
                  <label htmlFor="a">Available All Day</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="b"
                    name="a"
                    checked={
                      availabilities.allDays[currentDay].availabilityType === 1
                    }
                    onClick={() => handelAvailability(1)}
                  />
                  <label htmlFor="b">Set time</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="c"
                    name="a"
                    checked={
                      availabilities.allDays[currentDay].availabilityType === 2
                    }
                    onClick={() => handelAvailability(2)}
                  />
                  <label htmlFor="c">Closed</label>
                </div>
              </div>
              <div className={style.addNewListingPopUpAvailabilityTime}>
                <CustomTimePicker
                  name={"generalStartTime"}
                  is24Hour={true} // Use 24-hour format
                  label={"General Start Time"}
                  placeholder={"Select General Start Time"}
                  onChange={
                    (time, timeString) =>
                      handelGeneralTime(time, timeString, "startTime") // Update the state or perform an action
                  }
                  value={
                    dataTosend.generalTime?.startTime
                      ? dayjs(
                          dataTosend.generalTime?.startTime,
                          "H:mm" // Ensure the time string matches this format
                        )
                      : "" // If no time is available, provide an empty string
                  }
                  disabled={
                    availabilities.allDays[currentDay].availabilityType === 2 // Disable based on a condition
                  }
                />
                <CustomTimePicker
                  is24Hour={true}
                  label={" General End Time"}
                  placeholder={" Select General End Time"}
                  disabled={
                    availabilities.allDays[currentDay].availabilityType === 2
                  }
                  onChange={(time, timeString) =>
                    handelGeneralTime(time, timeString, "endTime")
                  }
                  value={
                    dataTosend.generalTime?.endTime
                      ? dayjs(
                          dataTosend.generalTime?.endTime,
                          "H:mm" // Specify the correct format
                        )
                      : "" // Return null if no valid time
                  }
                />
              </div>
              {(availabilities.allDays[currentDay]?.availabilityType === 0 ||
                availabilities.allDays[currentDay]?.availabilityType === 1) && (
                <label htmlFor="same">
                  <input
                    type="checkbox"
                    id="same"
                    checked={
                      availabilities.allDays[currentDay]?.sameAsGeneralTime ||
                      false
                    } // Add a fallback value
                    onChange={(event) => handelSameAsGeneralTime(event)}
                  />
                  &nbsp; Same As General time
                </label>
              )}

              {getCurrentFilterObject().map((filteredData, index) => (
                <div
                  key={filteredData.id}
                  className={style.addNewListingPopUpAvailabilityTime}
                >
                  <CustomTimePicker
                    name=" "
                    is24Hour={true}
                    label={index === 0 && "Booking Start Time"}
                    placeholder="Select Booking Start Time"
                    disabled={
                      availabilities.allDays[currentDay].availabilityType ===
                        0 ||
                      availabilities.allDays[currentDay].availabilityType === 2
                    }
                    value={
                      filteredData.startTime
                        ? dayjs(filteredData.startTime, "H:mm")
                        : ""
                    }
                    onChange={(time, timeString) =>
                      handleBookingTimeChange(
                        timeString,
                        "startTime",
                        filteredData.id
                      )
                    }
                  />
                  <CustomTimePicker
                    label={index === 0 && "Booking End Time"}
                    placeholder="Select Booking End Time"
                    is24Hour={true}
                    disabled={
                      availabilities.allDays[currentDay].availabilityType ===
                        0 ||
                      availabilities.allDays[currentDay].availabilityType === 2
                    }
                    value={
                      filteredData.endTime
                        ? dayjs(filteredData.endTime, "H:mm")
                        : ""
                    }
                    onChange={(time, timeString) =>
                      handleBookingTimeChange(
                        timeString,
                        "endTime",
                        filteredData.id
                      )
                    }
                  />
                  {getCurrentFilterObject().length > 1 && (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleBookingEntryDelete(filteredData.id)}
                    >
                      <img src={cross} alt="Remove" />
                    </span>
                  )}
                </div>
              ))}

              <br />
              {availabilities.allDays[currentDay].availabilityType === 0 ||
                availabilities.allDays[currentDay].availabilityType === 2 ||
                availabilities.allDays[currentDay].sameAsGeneralTime ||
                (currentBookingLength <= 2 && (
                  <span
                    style={{ marginTop: "-20px" }}
                    className={style.addTeamDetailbtn}
                    onClick={addNewBookingEntry}
                  >
                    <MdOutlineAddBox /> &nbsp;<div>Add Booking Time</div>
                  </span>
                ))}
            </div>
          </div>
          <div>
            <label htmlFor="Repeat">
              <input
                type="checkbox"
                checked={availabilities.repeateForAllDays}
                id="Repeat"
                onChange={(event) => handleRepeatForAllDays(event)}
              />{" "}
              &nbsp; Repeat for all Days
            </label>

            <Button
              name={"Save"}
              style={{ height: "40px" }}
              type={"button"}
              onClick={saveAvailability}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Availability;
